import React, { Fragment, Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

import logo from "./kentac.png";

export default class Header extends Component {
  render() {
    return (
      <Fragment>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>
          <img src={logo} width="30" height="30" alt="logo" />
            Kent AC Membership</Navbar.Brand>
        </Navbar>
      </Fragment>
    );
  }
}
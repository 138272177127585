import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import TrackMembershipApp from "./TrackMembershipApp";

import "./index.css";

class IndexPage extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="container">
          <div className="section">
            <Router>
              <Route path="/:identifier" children={<TrackMembershipApp />} />
            </Router>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
// ========================================

ReactDOM.render(<IndexPage />, document.getElementById("root"));

import React, { Component } from "react";
import { useParams } from "react-router";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { GoogleSpreadsheet } from "google-spreadsheet";

function User(Component) {
  return function App(props) {
    let { identifier } = useParams();
    return <Component {...props} identifier={identifier} />;
  };
}

function AnnualPass(props) {
  const hasAnnualPass = props.hasAnnualPass;
  if (hasAnnualPass === "Y") {
    return (
      <Button variant="warning" size="lg" block href="https://kentac.sumupstore.com/products/">
        2023 Annual Pass
      </Button>
    );
  }
  else if (hasAnnualPass === "J") {
    return (
      <Button variant="warning" size="lg" block href="https://kentac.sumupstore.com/products/">
        2023 Junior Pass
      </Button>
    );
  }
  else if (hasAnnualPass === "C") {
    return (
      <Button variant="warning" size="lg" block href="https://kentac.sumupstore.com/products/">
        Coach
      </Button>
    );
  }
  else {
    return null;
  }
}

function Pass(props) {
  if (props.has2021pass === "Y" || props.has2021pass === "J" || props.has2021pass === "C") {
    return null;
  } else if (props.hasPass === "Y") {
    return (
      <Button variant="success" size="lg" block href="https://kentac.sumupstore.com/products/">
        {props.month} Pass
      </Button>
    );
  } else if (props.hasPass === "N") {
    return (
      <Button variant="danger" size="lg" block href="https://kentac.sumupstore.com/products/">
        {props.month} Pass
      </Button>
    );
  } else if (props.hasPass === "J") {
    return (
      <Button variant="success" size="lg" block href="https://kentac.sumupstore.com/products/">
        {props.month} Junior Pass
      </Button>
    );
  } else {
    return (
      <Button variant="secondary" size="lg" block href="https://kentac.sumupstore.com/products/">
        {props.month} Pass
      </Button>
    );
  }
}

function MembershipCheck(props) {
  const membershipExpiry = props.membershipExpiry;

  let membershipExpiryParts = membershipExpiry.split('/');
  let membershipExpiryDate = new Date (membershipExpiryParts[2], membershipExpiryParts[1] - 1, membershipExpiryParts[0]); //using a[1]-1 since Date object has month from 0-11

  var q = new Date();
  var m = q.getMonth();
  var d = q.getDate();
  var y = q.getFullYear();

  var date = new Date(y,m,d);

  if (membershipExpiry.length === 0) {
    return (
      <Button variant="secondary" size="lg" block href="https://www.kentac.org.uk/membership/">
        Unknown Membership
      </Button>
    );
  }
  else if (date <= membershipExpiryDate) {
    return (
      <Button variant="success" size="lg" block href="https://www.kentac.org.uk/membership/">
        Valid Membership
      </Button>
    );
  }
  else if (date > membershipExpiryDate) {
    return (
      <Button variant="danger" size="lg" block href="https://www.kentac.org.uk/membership/">
        Expired Membership
      </Button>
    );
  }
}

class LoadSheet extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      curTime: new Date().toLocaleString(),
      sheetRetrieved: false,
    };
  }

  async componentDidMount() {
    // create a Spreadsheet doc by the given ID
    const doc = new GoogleSpreadsheet(
      process.env.REACT_APP_GOOGLE_SPREADSHEET_ID
    );

    // authenticate
    await doc.useServiceAccountAuth({
      client_email: process.env.REACT_APP_GOOGLE_EMAIL,
      private_key: process.env.REACT_APP_GOOGLE_KEY.replace(/\\n/g, "\n"),
    });

    // loads document properties and worksheets
    await doc.loadInfo();
    const sheet = doc.sheetsByTitle[process.env.REACT_APP_GOOGLE_SHEET];
    const rows = await sheet.getRows();

    if (sheet) {
      // Wait for sheet to be populated.
      this.setState({
        sheetRetrieved: true,
        data: rows.filter(
          (alldata) => alldata.identifier === this.props.identifier
        ),
        curTime: new Date().toLocaleString(),
      });
    }
  }

  render() {
    if (!this.state.sheetRetrieved) {
      return (
        <div>
          <Card>
            <Card.Header>Loading</Card.Header>
            <Card.Body>
              <Card.Text>Hold on while we lookup your membership...</Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
              {this.state.curTime}
            </Card.Footer>
          </Card>
        </div>
      );
    } else if (this.state.data.length > 0) {
      const monthFields = [
        "janPass",
        "febPass",
        "marPass",
        "aprPass",
        "mayPass",
        "junPass",
        "julPass",
        "augPass",
        "sepPass",
        "octPass",
        "novPass",
        "decPass",
      ];

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const currentMonth = monthNames[new Date().getMonth()];
      const currentMonthField = monthFields[new Date().getMonth()];



      return (
        <div>
          {this.state.data.map((data) => (
            <Card>
              <Card.Header>{data.name}</Card.Header>
              <Card.Body>
                <Card.Text>
                  <MembershipCheck membershipExpiry={data.membershipExpiry} />
                </Card.Text>
                <Card.Text>
                  <Pass
                    month={currentMonth}
                    hasPass={data[currentMonthField]}
                    has2021pass={data.annualPass}
                  />
                </Card.Text>
                <Card.Text>
                  <AnnualPass hasAnnualPass={data.annualPass} />
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                {this.state.curTime}
              </Card.Footer>
            </Card>
          ))}
        </div>
      );
    } else if (this.state.data.length === 0) {
      return (
        <div>
          <Card>
            <Card.Header>Error</Card.Header>
            <Card.Body>
              <Card.Text>Your membership details cannot be found.</Card.Text>
              <Card.Text>
                Please try and re-load the link from your email.
              </Card.Text>
              <Card.Text>
                Contact{" "}
                <a href="mailto:trackpass@kentac.org.uk">
                  trackpass@kentac.org.uk
                </a>{" "}
                for more help.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
              {this.state.curTime}
            </Card.Footer>
          </Card>
        </div>
      );
    }
  }
}

export default User(LoadSheet);
